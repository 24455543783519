import Adsense from './ads/Adsense'
import InArticleAdsense from './ads/InArticleAdsense'
import InFeedAdsense from './ads/InFeedAdsense'
import AutoAdsense from './ads/AutoAdsense'

export default {
  Adsense,
  InArticleAdsense,
  InFeedAdsense,
  AutoAdsense,
}
